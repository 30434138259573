@import "src/constants";

a {
  color: black;
  text-decoration: none;
}

.container {
  width: calc(100% - 12px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: $navigationBarHeight;
  background-color: $commonBackground;
  color: #FFF;
  border-bottom: 2px solid #FFFFFF;
  margin: 0 6px;

  .leftContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    color: black;

    .iconContainer {
      display: flex;
      flex-direction: column;
      zoom: 0.9;

      .title {
        font-family: 'Lato', sans-serif;
        font-size: 11px;
        line-height: 11px;
        font-weight: 500;
        color: gray;
        text-align: center;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        letter-spacing: 2.5px;
      }
    }
  }

  .topPanelRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    color: black !important;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;

    .row {
      @media (max-width: 1860px) {
        zoom: 0.9;
      }

      .rowItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        .textLink {
          font-family: 'Lato', sans-serif;
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: black !important;
          text-decoration: none;
        }

        .text {
          color: black;
          font-family: 'Lato', sans-serif;
          font-size: 15px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          text-align: right;
        }

        .textBalance {
          color: $colorBalance;
          font-family: 'Lato', sans-serif;
          font-size: 14px;
          font-weight: 800;
          line-height: 15px;
          letter-spacing: 0.05em;
          text-align: right;
        }

      }

    }

    .buttonAction {
      width: 125px !important;
      font-family: 'Lato', sans-serif;
      font-size: 16px !important;
      font-weight: 500 !important;
      line-height: 19px !important;
      letter-spacing: 0em !important;
      cursor: pointer;
      height: 35px !important;
      background-color: $correctThemeColor;
      border-radius: 5px;
      border-width: 0;
      border-color: transparent;
      text-align: center;
      text-transform: none;
      color: white;
    }

    .buttonCancel {
      font-family: 'Lato', sans-serif;
      font-size: 16px !important;
      font-weight: 500 !important;
      line-height: 19px !important;
      letter-spacing: 0em !important;
      text-transform: none;
      cursor: pointer;
      width: 125px !important;
      height: 35px !important;
      background-color: transparent !important;
      border: 1px solid $correctThemeColor !important;
      text-align: center;
      color: #000;
      border-radius: 5px;
    }


    .itemColumn {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .topPanelRightAvatarCont {
      display: flex;
      align-items: center;
      color: $correctThemeColor;
    }

    .topPanelRightAvatar {
      cursor: pointer;
      color: $correctThemeColor;
      margin-right: 10px;
    }

    .topPanelRightAvatarUserName {
      color: $correctThemeColor;
      cursor: pointer;
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      font-weight: 800;
      line-height: 16px;
      letter-spacing: 0.05em;
      text-align: left;

    }

  }

  .logo {
    width: 180px;
    height: 29px;
    background: no-repeat url("logo.png");
    background-size: contain;
  }

  .link {
    font-family: 'Lato', sans-serif;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 180px !important;
    height: 35px !important;
    background-color: $correctThemeColor !important;
    color: #FFFFFF !important;
    text-decoration: none;
    text-transform: none !important;
    outline: none;
    border: none;
  }

  .linkPdfTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  .linkPdf {
    font-family: "Lato", "SansSerif", sans-serif;
    font-size: 16px !important;
    font-weight: 300 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.87) !important;
    width: 180px !important;
    height: 35px !important;
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.77) !important;
    text-decoration: none;
    text-transform: none !important;
    outline: none;
  }

  .arrowDown {
    margin-left: 3px;
    cursor: pointer;
    width: 17px;
    height: 17px;
    background: no-repeat url("arrowDown.svg");
  }

  .infoIcon {
    cursor: pointer;
    width: 25px;
    height: 25px;
    background: no-repeat url("info.svg");
    margin-right: 11px;
  }

}

.shareContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1700px) {
    zoom: 0.9;
    margin-left: 10px;
  }
}

.actionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 32px;

  &Row{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &Info {
    display: flex;
    flex-direction: row;
    gap: 8px;

    @media (min-width: 1750px) {
      width: 225px;
    }

    color: #000000;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    line-height: 16px;
    font-weight: 500;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 1600px) {
      zoom: 0.9;
      gap: 2px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &InfoEmpty {
    width: 225px;
  }

}

.actionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1500px) {
    zoom: 0.9;
  }
  width: calc(100% - 790px);
}

.actsHaveDifference {
  cursor: pointer;
  width: 35px;
  height: 35px;
  @extend %actsHaveDifference
}
.actsNotRecognized {
  cursor: pointer;
  width: 35px;
  height: 35px;
  @extend %actsNotRecognized
}

.waitingForPair {
  cursor: pointer;
  width: 35px;
  height: 35px;
  @extend %waitingForPair
}

.disabled {
  @extend %disabled;
}

.mirror {
  transform: scale(-1, 1) !important;;
}

.mirrorY {
  transform: rotate(90deg) !important;
  width: 28px !important;
}

.icon {
  width: 28px !important;
  height: 28px !important;
}

.zoomTooltip {
  background-color: transparent !important;
  height: 250px;
  width: 50px;
  z-index: 1000;
}

.diffFilterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative !important;
}

.iconsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.filterRow {
  display: flex;
  flex-direction: row;
  gap: 8px;

  .text {

  }

}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectedItem {
  background-color: rgba(177, 165, 165, 0.34) !important;
  border: 5px solid black;
  box-shadow: 0px 0px 5px 0px rgba(177, 165, 165, 0.34);
}

.documentPositionIcon {
  object-fit: contain;
}

.documentPositionIconVertical {
  width: 36px !important;
  object-fit: contain;
}

.setButtonContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 4px;

  .setDefaultButton {
    font-size: 14px !important;
    color: black !important;
    text-transform: none !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400 !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.divider {
  width: 100%;
  background-color: #000;
  height: 1px;
}

.topPanelRightAvatarMenu {
  z-index: 1100;
}

.exportToPdfButton {
  width: 26px !important;
  height: 26px !important;
  object-fit: contain;
}

.infoText {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 200px;
  padding: 2px;
  gap: 2px;

  &Row{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: "Lato", "SansSerif", sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0em;
    text-align: left;
  }
}
