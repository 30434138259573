@import "src/constants";

.notEmpty {
  background-color: #FBFBFB;
  border-radius: 10px;
}

.dropZone {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $correctThemeColor;
  border-radius: 10px;

  p{
    margin: 0 !important;
    padding: 0!important;
  }
}

.dropZoneTitle {
  font-family: 'Lato', "Open Sans", "SansSerif", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  color: #000;
  margin-top: 86px;
}

.marginZero {
  margin: 0 !important;
}

.uploadTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 72px;

  .dropZoneLabel {
    text-transform: uppercase;
    color: #00000033;
    font-family: 'Lato', "Open Sans", "SansSerif", sans-serif;
    font-size: 32px;
    font-weight: 300;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: center;
  }

  .imageIcon {
    width: 150px;
    height: 150px;
    background: no-repeat url("image.svg");
    margin-top: 30px;
    margin-bottom: 65px;
  }

  .imageSmallIcon {
    width: 40px;
    height: 40px;
    background: no-repeat url("imageSmall.svg");
    margin-top: 25px;
    margin-bottom: 6px;
  }

  .smallLabel {
    font-family: 'Lato', "Open Sans", "SansSerif", sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
    color: #00000033;
  }

}



.dropZoneFooter {
  margin-bottom: 60px;
  font-family: 'Lato', "Open Sans", "SansSerif", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.dropZoneContainer {
  margin: 16px;
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 100%;
  }

}
