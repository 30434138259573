.tableContainer {
  width: 100%;
  th{
    padding: 8px;
  }
  tfoot {
    border: none;
    td{
      border-color: transparent !important;
      padding-right: 120px !important;
    }
    >div {
      display: flex !important;
      padding-right: 60px !important;
      flex-direction: row-reverse !important;
    }
  }
  div {
    width: auto;
  }
  td {
    padding: 2px 8px !important;
    line-height: 14px;
  }
  th:first-child {
    padding: 2px 8px !important;
  }


  .addButton {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    cursor: pointer;
    background-color: #0081ff;
    right: 25px;
    top: 12px;
    outline: none;
    border-width: 0;
    border-radius: 50%;
    z-index: 20;
    width: 32px;
    height: 32px;

    .addButtonIcon {
      color: white;
    }
  }

  @media (max-width: 991px) {
    .addButton {
      position: relative;
      top: 10px;
      right: auto;
      left: 20px;
    }
  }
}
