@import "src/constants";

.footer {
  width: calc(100% - 44px) !important;
  background-color: $grayBackground;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: $footerHeight;
  padding: 0 38px 0 6px;

  .leftPart {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &Row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 5px;

      &LeftItem {
        font-family: 'Lato', sans-serif;
        font-size: 11px;
        font-weight: 500;
             line-height: 12px;
        letter-spacing: 0em;
        color: #fff;
        text-decoration: none;
        align-self: center;
      }

      &RightItem {
        color: #7f7f7f;
        font-family: 'Lato', sans-serif;
        font-size: 11px;
        font-weight: 500;
             line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }

  .rightPart {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 32px;
    align-items: center;
    padding-right: 60px;

    &Row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 5px;

      &LeftItem {
        font-family: 'Lato', sans-serif;
        font-size: 11px;
        font-weight: 500;
             line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
        text-decoration: none;
        align-self: end;
      }

      &RightItem {
        color: #7f7f7f;
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        font-size: 11px;
        letter-spacing: 0em;
        text-align: left;
        align-self: end;
             line-height: 12px;
      }

      &RightLink {
        font-family: 'Lato', sans-serif;
        font-size: 11px;
        font-weight: 500;
             line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
        text-decoration: none;
        align-self: end;
      }

      &RightLinkNoDecoration {
        text-decoration: none;
      }


    }

  }

  .divider {
    border: 0.5px solid #747474;
  }

  .logo {
    width: 50px;
    height: 50px;
    background: no-repeat url("footerLogo.svg");
  }
}

@media (max-width: 780px) {
  .footer {
    display: flex;
    gap: 16px;
  }
}
