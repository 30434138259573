@import "src/constants";

.wrapperContainer {
  width: 100%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $commonBackground;

  .pagesContainer {
    width: 100%;
    overflow: auto;
  }

  .displayFooter {
    height: calc(100vh - #{$navigationBarHeight} - #{$footerHeight} - 2px) !important;
  }

  .hideFooter {
    height: calc(100vh - 47px) !important;
  }

}