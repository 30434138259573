@import 'src/constants';

.popupBackground {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9000;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;

  .popupContainerEmptyDocList {
    height: 700px !important;
    width: 1440px !important;
  }

  .popupContainerNotEmptyDocList {
    height: 900px;
    width: 1600px;
  }

  .backgroundNotEmpty {
    background-color: $commonBackground !important;
  }

  .popupContainer {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #FFFFFF;
    position: relative;
    height: 900px;
    width: 1300px;
    padding: 0 55px 0 25px;

    @media (max-width: 1500px) {
      zoom: 0.9;
    }
    @media (max-width: 1200px) {
      zoom: 0.8;
    }

    @media (max-height: 900px) {
      zoom: 0.9;
    }

    @media (max-height: 800px) {
      zoom: 0.8;
    }

    @media (max-height: 700px) {
      zoom: 0.75;
    }

    @media (max-height: 600px) {
      zoom: 0.6;
    }

    .gridHeight{
      width: 100%;
      height: 780px !important;
    }

    .gridHeightSmall{
      width: 100%;
      height: 555px !important;
    }

    .uploadContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 15px;
      height: 100%;
      margin: 50px 25px 0px 15px;
      cursor: pointer;
    }

    .uploadContainerNotEmpty {
      display: flex;
      flex-direction: row;
      background-color: $commonBackground !important;
      gap: 25px;

      .leftPart {
        width: 660px !important;
        height: 865px;
        display: flex;
        flex-direction: column;
        gap: 28px;

        .topPart {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border-radius: 10px;
          background-color: #FFFFFF;
          height: 785px;

          .sortListContainer {
          }

          .dropzoneUploadContainer {
            width: 100%;
            height: 146px;
            margin-bottom: 40px;
          }

        }

      }

      .rightPart {
        min-width: 606px;
        height: 865px;
        background-color: #FFFFFF;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1300px) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 100%;
          min-width: 300px;
        }
      }

    }

    .closeIcon {
      border-radius: 0 !important;
      position: absolute;
      top: 9px;
      right: 10px;
      cursor: pointer;
      width: 35px;
      height: 35px;
      background: no-repeat url("close.svg");
    }

  }
}

.loading {
  display: flex;
  width: 500px;
  height: 800px;
  justify-content: center;
  align-items: center;
}

.pdfFilesContainerPaper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  height: 100%;
  overflow-y: hidden;
}

.pdfFilesContainer {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 100% !important;
  height: 100%;
  overflow-y: auto !important;
  gap: 16px;

  @media (max-width: 1300px) and (orientation: landscape) {
    height: 100%;
    overflow-x: auto;
  }

}

.uploadButton {
  font-family: 'Lato', sans-serif;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 220px !important;
  height: 50px !important;
  background: $correctThemeColor !important;
  color: #FFFFFF !important;
  text-decoration: none;
  text-transform: none !important;
  text-wrap: none;
  outline: none;
  border: none;
}

.cancelButton {
  font-family: 'Lato', sans-serif;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 222px !important;
  height: 50px !important;
  border: 1px solid $correctThemeColor !important;
  background-color: transparent !important;
  color: #000 !important;
  text-decoration: none;
  text-transform: none !important;
  outline: none;
}

.disabled {
  @extend %disabled;
}

.pdfFile {
  width: 490px;
  height: 820px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
  position: relative;

  .documentButtonsContainer {
    width: calc(100% - 100px);
    max-width: 390px;

    margin-left: 60px;
    margin-right: 40px;
    margin-bottom: 48px;

    .top {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &Left{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 22px;
    }

    &Right {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      .pageNumberTitle {
        font-family: 'Lato', sans-serif;
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    .documentButton {
      margin: 16px;
      cursor: pointer;
    }
  }

  .invertedPageContainer {
    width: 485px !important;
    height: 490px !important;
    margin-top: 50px;
  }

  .pageContainer {
    width: 490px;
    height: 650px;
    overflow: auto;
  }

  .container {
    display: flex;
    overflow-y: auto;
    width: 490px;
    height: 650px;
  }

  .containerRotate {
    height: 580px !important;
  }

  .containerCenter {
    justify-content: center;
  }

  .fileName {
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 15px;
    font-family: "Open Sans", "SansSerif", sans-serif;
    color: black;
  }

}

.rotateHorizontal {
  width: 580px;
  height: 820px;
}

.rotate90 {
  transform: rotate(90deg) !important;
}

.rotate180 {
  transform: rotate(180deg) !important;
}

.rotate270 {
  transform: rotate(270deg) !important;
}

.rotateMinus90 {
  transform: rotate(-90deg) !important;
}

.rotateMinus180 {
  transform: rotate(-180deg) !important;
}

.rotateMinus270 {
  transform: rotate(-270deg);
}

.inputDocuments {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;
  box-sizing: border-box;
  padding-left: 8px;
  padding-top: 8px;
  height: 280px;
  gap: 6px;

  .selectedItem {
    border: 3px solid $correctThemeColor !important;
    box-shadow: 0px 0px 8px 0px $correctThemeColor !important;
  }

  &Item {
    width: 140px !important;
    border-radius: 5px;
    color: white;
    outline: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 4px 4px !important;
    text-align: center !important;
    text-decoration: none;
    padding-bottom: 15px !important;
    height: 245px !important;
    border: 1px solid $correctThemeColor;

    .closeRow {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 17px !important;

      .icon {
        background: no-repeat url("bin.svg");
        width: 20px !important;
        height: 20px !important;
        margin: 0 0 0 0 !important;
        padding: 0 !important;
        cursor: pointer;
      }

    }

    .documentContainer {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .filePreview {
      width: 135px;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &Description {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none !important;
      color: #000000;
      font-family: 'Lato', sans-serif;
      font-size: 14px;
      font-weight: 300;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: center;

      margin-top: 5px;
      overflow-wrap: normal;
      height: 14px !important;
      word-break: break-word;
    }
  }
}

.rotateLeftIcon {
  @extend %rotateLeftIcon;
  width: 30px !important;
  height: 30px !important;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.rotateRightIcon {
  @extend %rotateRightIcon;
  width: 30px !important;
  height: 30px !important;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.zoomInIcon {
  @extend %zoomInIcon;
  width: 30px !important;
  height: 30px !important;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.zoomOutIcon {
  @extend %zoomOutIcon;
  width: 30px !important;
  height: 30px !important;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.scaleIcon {
  @extend %scaleIcon;
  width: 30px !important;
  height: 30px !important;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.arrowLeft {
  @extend %arrowLeft;
  width: 15px !important;
  height: 15px !important;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}
.arrowRight {
  @extend %arrowRight;
  width: 15px !important;
  height: 15px !important;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.loadingDocument {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid $correctThemeColor;
  border-radius: 10px;
}

.uploadSuccess {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid $correctThemeColor;
  border-radius: 10px;
  gap: 5px;

  &Label {
    font-family: 'Lato', "Open Sans", "SansSerif", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
    color: #000;
  }

}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  margin: 10px 15px 15px 50px
}
