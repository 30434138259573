@import "src/constants";

.pageContainer {
  width: calc(100% - 6px);
  height: calc(100% - 10px);
  display: flex;
  flex-direction: row;
  padding: 5px 3px 5px 3px;
  gap: 2px;
  .leftPart {
    display: flex;
    flex-direction: column;
    width: calc(100% - 300px);
    height: 100%;
    background-color: $grayBackground;

    .rowItemContainer {
      width: calc(100% - 2px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: calc(50% - 2.5px);
      overflow-y: auto;
      background-color: #FFFFFF;
      border: 1px #000000 solid;

      .documentsContainer {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        overflow: auto;
      }

      .centering {
        align-items: center !important;
      }

    }
  }

  .rightPart {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .commentsEmptyContainer {
      justify-content: center !important;
      align-items: center !important;
    }

    .commentsContainer {
      width: calc(100% - 5px);
      height: calc(100% - 50px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2px;

      .subContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2px;
      }

      &Item {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        background-color: $commonBackground;
        border-radius: 0 !important;
        padding: 3.5px;
        width: calc(100% - 10px) !important;
        outline: none;

        &WrapperEmpty {
          justify-content: center !important;
          align-items: center !important;
        }

        &Wrapper {
          margin: 8px 8px 8px 8px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          &ItemEnpty {
            padding: 4px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-family: "Lato", "SansSerif", sans-serif;
            font-size: 20px;
            font-weight: 300;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
          }

          &Item {
            display: flex;
            flex-direction: row;
            font-family: "Lato", "SansSerif", sans-serif;
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0em;
            text-align: left;
            outline: none;

            .nameRow {
              width: 260px;
              min-width: 248px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }

            .text {
              max-width: 248px;
              font-size: 12px;
              line-height: 14px;
              word-wrap: break-word;
              overflow-wrap: break-word;
            }

            span {
              font-size: 12px;
              line-height: 14px;
            }

            .bold {
              font-weight: bold;
            }

            &Favourite {
              background: pink;
            }
          }

          &ItemClose {
            width: 100%;
            display: flex;

          }

        }
      }

      &Item:hover {
        border: 1px solid $grayBackground;
        padding: 2.5px;
      }
    }

  }
}

.title {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  font-family: 'Lato', sans-serif;
  background-color: $commonBackground;
  text-align: left;
}

.selectedItem {
  padding: 0px 0px 0px 0.5px !important;
  border: 3.5px solid $grayBackground !important;
}

.hidden {
  display: none !important;
}

.emptyComment {
  background-color: transparent !important;
  justify-content: center !important;
}

.documentButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 22px;
  margin-bottom: 6px;
  margin-top: 6px;
  color: rgba(0, 0, 0, 0.54)
}

.commentsContainerItemCollapsed {
  min-height: auto !important;
  background: #c0ffc9 !important;
}

.rotateLeftIcon {
  @extend %rotateLeftIcon;
  width: 25px !important;
  height: 25px !important;
  background-size: contain;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.rotateRightIcon {
  @extend %rotateRightIcon;
  width: 25px !important;
  height: 25px !important;
  background-size: contain;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.rotateRight180Icon {
  @extend %rotateRight180Icon;
  width: 34px !important;
  height: 34px !important;
  background-size: contain;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
  margin-left: -6px !important;
}

.zoomInIcon {
  @extend %zoomInIcon;
  width: 25px !important;
  height: 25px !important;
  background-size: contain;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.zoomOutIcon {
  @extend %zoomOutIcon;
  width: 25px !important;
  height: 25px !important;
  background-size: contain;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.scaleIcon {
  @extend %scaleIcon;
  width: 25px !important;
  height: 25px !important;
  background-size: contain;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border-radius: 0 !important;
}

.pushpinIcon {
}

.pushpinIconActive {
  color: red !important;
}

.materialButtonColor {
  height: 50px;
  width: 100% !important;
  border-radius: 5px;
  background-color: #F57B20 !important;
  font-size: 16px !important;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  font-family: 'Lato', sans-serif;
  padding: 0 !important;
}

.titleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-top: 2px;

  .titleNoMargin {
    width: auto !important;
    margin: 0 0 0 8px !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 17px !important;
  }
}

.actsHaveDifference {
  cursor: pointer;
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  @extend %actsHaveDifference
}

.actsNotRecognized {
  cursor: pointer;
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  @extend %actsNotRecognized
}

.waitingForPair {
  cursor: pointer;
  width: 35px;
  height: 35px;
  min-width: 36px;
  min-height: 36px;
  @extend %waitingForPair
}

.actsMatch {
  cursor: pointer;
  width: 35px;
  height: 35px;
  min-width: 36px;
  min-height: 36px;
  @extend %actsHasMatch
}

.actsRejected {
  cursor: pointer;
  width: 35px;
  height: 35px;
  min-width: 36px;
  min-height: 36px;
  @extend %actsRejected
}

.actPassed {
  cursor: pointer;
  width: 35px;
  height: 35px;
  min-width: 36px;
  min-height: 36px;
  @extend %actPassed
}

.titleContainer {
  width: 100%;
  background: $commonBackground;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.materialButtonHelp {
  height: 40px !important;
  min-width: 30px !important;
  border-radius: 5px;
  background-color: $colorBalance !important;
  font-size: 10px !important;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  align-items: center !important;
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 6px !important;
}

.materialButtonComment {
  height: 40px !important;
  min-width: 30px !important;
  border-radius: 5px;
  background-color: $correctThemeColor !important;
  font-size: 10px !important;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  align-items: center !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4px;
  margin-top: 6px !important;
}

.buttonChangeStatusFullWidth{
  padding: 6px 21px !important;
}

.buttonHelpFullWidth{
  padding: 6px 18px !important;
}

.materialButtonChangeStatus {
  height: 40px !important;
  min-width: 30px !important;
  border-radius: 5px;
  background-color: green !important;
  font-size: 10px !important;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  align-items: center !important;
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 6px !important;
  position: relative;
}

.hidePanelContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.linkPdf {
  font-family: "Lato", "SansSerif", sans-serif;
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  height: 49px !important;
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.77) !important;
  text-decoration: none;
  text-transform: none !important;
  outline: none;
}

.colorChanged {
  color: $changedColor !important;
}

.colorDelete {
  color: red !important;
}

.colorAdded {
  color: green;
}

.panelLeft {
  flex-direction: row-reverse !important;
}

.docLeft {
  flex-direction: row !important;
}

.mirror {
  transform: scale(-1, 1) !important;;
}

.buttonFullWidth {
  width: 290px !important;
}

.buttonShort {
  width: 59px!important;
}

.pageLoading {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;

  &Label {
    font-family: 'Lato', "Open Sans", "SansSerif", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
    color: #000;
  }

}

.wrapperNoMargin {
  margin: 0 !important;
}

.centerIcon {
  align-items: center !important;
  justify-content: center !important;
}

.noMargin {
  margin: 0 !important;
}

.emptyText {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  text-align: center;
}

.filteredDiscrepancyText {
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
  letter-spacing: 0em;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  text-align: center;
}

.addedStamp {
  width: 32px;
  height: 32px;
  object-fit: contain;
  background: no-repeat url("stamp_icon.svg");
}

.doubleIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 24px;
  height: 24px;
}

.doubleIconContainer {
  width: calc(100% - 23px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 11.5px;
  height: 32px;
};
