$commonBlue: #3066F0;
$commonBackground: #F2F7FA;
$commentBackground: #f9f9f9;
$grayBackground: #515151;
$correctThemeColor: #F57B20;
$changedColor: #e020f5;
$colorBalance: rgba(35, 81, 187, 1);
$selectedDiffColor: rgba(245, 123, 32, 0.6);
$footerHeight: 20px;
$navigationBarHeight: 45px;

%disabled {
  opacity: 0.5;
  filter: grayscale(1);
}

%selected {
  box-shadow: 0px 0px 3px 3px $commonBlue !important;
}

%rotateLeftIcon {
  background: no-repeat url("rotateLeft.svg");
}

%rotateRightIcon {
  background: no-repeat url("rotateRight.svg");
}

%rotateRight180Icon {
  background: no-repeat url("rotateRight180.svg");
}

%zoomInIcon {
  background: no-repeat url("zoomPlus.svg");
}

%zoomOutIcon {
  background: no-repeat url("zoomMinus.svg");
}

%scaleIcon {
  background: no-repeat url("scale.svg");
}

%arrowLeft {
  background: no-repeat url("arrowLeft.svg");
}

%arrowRight {
  background: no-repeat url("arrowRight.svg");
}

%actsHaveDifference {
  background: no-repeat url("twoDocsErrorStatus.svg");
}

%actsHasMatch {
  background: no-repeat url("twoDocOkStatus.svg");
}

%actsRejected {
  background: no-repeat url("processFinishErrorStatus.svg");
}

%actPassed {
  background: no-repeat url("processFinishokStatus.svg");
}

%waitingForPair {
  background: no-repeat url("waitingForPair.svg");
}

%actsNotRecognized {
  background: no-repeat url("processError.svg");
}
