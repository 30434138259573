@import "src/constants";
.table {
  box-sizing: border-box;
  width: 99%;
  display: flex;
  flex-direction: column;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 16px;
  height: 100%;

  .toolbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
  }

}

.react-daterange-picker__button{
  display: none !important;
}

.react-daterange-picker__wrapper{
  background-color: #0081ff !important;
}

.iconRow {
  width: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.react-daterange-picker__wrapper {

}

.processesStatus {
  text-align: center;
  color: white;
  border: none;
  outline: none;
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  cursor: pointer;
}

.positiveStatus {
  color: #19bc9c;
}

.negativeStatus {
  color: #CC1F16;
}
.warningStatus {
  color: #F57B20;
}

.filterIcon {
  height: 41px;
  max-height: 50px;
  border-radius: 50%;
  margin-top: 10px;
}

.searchIcon {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: no-repeat url("search.svg");
}

.processing {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: no-repeat url("processing.svg");
}

.processError {
  cursor: pointer;
  width: 35px;
  height: 35px;
  @extend %actsNotRecognized;
}

.twoDocsErrorStatus {
  cursor: pointer;
  width: 35px !important;
  height: 35px !important;
  @extend %actsHaveDifference;
}

.deletedDoc {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: no-repeat url("deletedDoc.svg");
}

.isDisabled {
  color: (srgb 0.6286 0.6436 0.652) !important;
}
