@import "src/constants";

.pdfFile {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 100;

  .loadingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .documentButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .documentButton {
      margin: 16px;
      cursor: pointer;
    }

  }

  .pageContainer {
    object-fit: contain;
    position: relative;
  }

  .polyLine {
    z-index: 1;
    position: absolute;
    cursor: pointer;
    padding: 4px;
    transition-duration: 0.3s;
    transition-property: width;
    //transition: background-color 0.15s ease-out;
  }

  .polyLine:hover {
  }

  .fileName {
    text-align: center;
    font-size: 15px;
    font-family: "Open Sans", "SansSerif", sans-serif;
  }

}

.selectedItem {
  border: 3.5px solid !important;
}

.selectedItem:hover {
}

.rotateHorizontal {
  width: 100% !important;
  height: auto;
}

.rotateVertical {
  width: 820px !important;
  height: 820px !important;
}

.rotate90 {
  transform: rotate(90deg) !important;
}

.rotate180 {
  transform: rotate(180deg);
}

.rotate270 {
  transform: rotate(270deg);
}

.rotateMinus90 {
  transform: rotate(-90deg);
}

.rotateMinus180 {
  transform: rotate(-180deg);
}

.rotateMinus270 {
  transform: rotate(-270deg);
}

@keyframes changed-blinking {
  0% {
    background-color: #e020f5;
  }
  100% {
    background-color: rgba(224, 32, 245, 0.2);
  }
}

.colorChangedDetailed {
  animation: changed-blinking 0.7s ease-out;
}

@keyframes delete-blinking {
  0% {
    background-color: red;
  }
  100% {
    background-color: rgba(255, 0, 0, 0.2);
  }
}

.colorDeleteDetailed {
  animation: delete-blinking 0.7s ease-out;
}

@keyframes added-blinking {
  0% {
    background-color: green;
  }
  100% {
    background-color: rgba(0, 128, 0, 0.2);
  }
}

.colorAddedDetailed {
  animation: added-blinking 0.7s ease-out;
}

.colorChangedSelected {
  color: $changedColor !important;
  box-shadow: 0px 0px 8px 0px $changedColor !important;
  border: 1px solid $changedColor !important;
  padding: 1px;
}

.colorChanged {
  color: $changedColor;
  background-color: rgba(224, 32, 245, 0.2);
}

.colorDelete {
  color: red !important;
  background-color: rgba(255, 0, 0, 0.2);
}

.colorAdded {
  color: green!important;
  background-color: rgba(0, 128, 0, 0.2);
}

.colorDeleteSelected {
  box-shadow: 0px 0px 8px 0px red !important;
  border: 1px solid red;
}

.colorAddedSelected {
  border: 1px solid green;
  box-shadow: 0px 0px 8px 0px green !important;
}

.canvasArea {
  border: 1px solid $grayBackground;
  position: absolute;
  z-index: 13000;
  background-color: #FFFFFF;
}
